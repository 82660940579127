<template>
  <b-card-code title="Employee List">

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :job-title-options="jobTitleOptions"
      :show-options="showOptions"
      @refetch-data="refetchData"
    />

    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
          <b-button
            variant="primary"
            @click="isAddNewUserSidebarActive = true"
          >
            <span class="text-nowrap">Add User</span>
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      ref="refUserListTable"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullname'"
          class="text-nowrap"
        />

        <div
          v-if="props.column.field === 'email'"
          class="text-nowrap"
        />

        <div v-if="props.column.field === 'role'">
          <feather-icon
            :icon="userRoleIcon(props.row.role)"
            size="18"
            class="mr-50"
            :class="`text-${userRoleIcon(props.row.role)}`"
          />{{ props.row.role }}
        </div>

        <span
          v-else-if="props.column.field == 'shows'"
          v-html="props.formattedRow['shows']"
        />
        <span
          v-else-if="props.column.field == 'positions'"
          v-html="props.formattedRow['positions']"
        />

        <span
          v-else-if="props.column.field == 'user_info'"
          v-html="props.formattedRow['user_info']"
        >{{ props.row.user_info }}</span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'active'">
          <b-badge :variant="statusVariant(getActive(props.row.active))">
            {{ getActive(props.row.active) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: props.row.id } }">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDeleteConfirm(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['2','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    UserListAddNew,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isAddNewUserSidebarActive: false,
      roleOptions: [],
      jobTitleOptions: [],
      showOptions: [],
      pageLength: 15,
      dir: true,
      columns: [
        {
          label: 'Name',
          field: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Role',
          field: 'role',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Role',
          },
        },
        {
          label: 'Shows',
          field: 'shows',
          formatFn: this.formatData,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Show',
          },
        },
        {
          label: 'Job Title',
          field: 'positions',
          formatFn: this.formatData,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Titles',
          },
        },
        {
          label: 'User Bio',
          field: 'user_info',
        },

        {
          label: 'Status',
          field: 'active',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        active: 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    userRoleVariant() {
      const roleColor = {
        /* eslint-disable key-spacing */
        'Super Admin'      : 'danger',
        Admin : 'warning',
        Employee     : 'success',
        Public     : 'info',
        /* eslint-enable key-spacing */
      }

      return role => roleColor[role]
    },

    userRoleIcon() {
      const resolveUserRoleIcon = {
        'Super Admin': 'ServerIcon',
        Admin: 'DatabaseIcon',
        Employee: 'UserIcon',
        Public: 'UserIcon',
      }
      return role => resolveUserRoleIcon[role]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    // Use toast
    const toast = useToast()

    store
      .dispatch('userStore/fetchUsers')
      .then(response => {
        this.rows = response.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.fetchShows()
    this.fetchRoles()
    this.fetchJobTitles()
  },
  methods: {
    formatData(valuesArray) {
      // return valuesArray.replace(/,/g, '<br>')
      return valuesArray.map(value => `<ul class="list-style-square"><li>${value.name}</li></ul>`).join('')
    },

    refetchData() {
      return this.$forceUpdate()
    },

    getActive(status) {
      if (status === '2') return 'pending'
      if (status === '1') return 'active'
      if (status === '0') return 'inactive'
      return 'could not fetch the status'
    },

    fetchRoles() {
      const toast = useToast()
      store
        .dispatch('rolesStore/fetchRoleLabels')
        .then(response => {
          this.roleOptions = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching roles list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    fetchShows() {
      const toast = useToast()
      store
        .dispatch('showStore/fetchShowLabels')
        .then(response => {
          this.showOptions = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching shifts list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    fetchJobTitles() {
      const toast = useToast()
      store
        .dispatch('jobTitleStore/fetchJobTitleLabels')
        .then(response => {
          this.jobTitleOptions = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching job titles list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showDeleteConfirm(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this user.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch('userStore/removeUser', { userId: id })
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.$bvToast.toast('We have deactivated the user successfully.', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('We could not process your action this time. please try again.', {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
              })
          }
          // console.log(`${value} ${id}`)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
